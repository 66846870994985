// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-focusing-on-business-value-js": () => import("./../../../src/pages/focusing-on-business-value.js" /* webpackChunkName: "component---src-pages-focusing-on-business-value-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microservices-js": () => import("./../../../src/pages/microservices.js" /* webpackChunkName: "component---src-pages-microservices-js" */),
  "component---src-pages-pay-as-you-go-js": () => import("./../../../src/pages/pay-as-you-go.js" /* webpackChunkName: "component---src-pages-pay-as-you-go-js" */),
  "component---src-pages-reactive-systems-js": () => import("./../../../src/pages/reactive-systems.js" /* webpackChunkName: "component---src-pages-reactive-systems-js" */),
  "component---src-pages-startup-and-enterprise-innovation-js": () => import("./../../../src/pages/startup-and-enterprise-innovation.js" /* webpackChunkName: "component---src-pages-startup-and-enterprise-innovation-js" */),
  "component---src-templates-markdown-template-js": () => import("./../../../src/templates/markdownTemplate.js" /* webpackChunkName: "component---src-templates-markdown-template-js" */)
}

